import logoSmall from "./logoSmall.png";

export default function HeaderNew(){
    return (<header className="p-3 mb-3 bg-white">
    <div className="container">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
          <img src={logoSmall} width="64px"/>
          <div style={{marginLeft: 20, fontWeight: 30 }}>Rinkt Mapping Station</div>
        </a>

        {/*<ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><a href="#" className="nav-link px-2 link-secondary">Overview</a></li>
         </ul>*/}
      </div>
    </div>
  </header>)
}
