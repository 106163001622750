import {ConstInterface} from "./definitions"

class NirItem {
    item: any
    item_map: ConstInterface

    constructor(item: any, item_map: ConstInterface){
        this.item = item;
        this.item_map = item_map;
    }

    cantitate(): number{
        return parseFloat(this.item.cantitate) * this.item_map.scalare;
    }

    valoare_fara_tva(): number{
        return this.pret() * this.cantitate();
    }

    valoare_tva(): number{
        return this.item["tva"] != "" ? this.valoare_fara_tva() * parseFloat(this.item["tva"])/100 : parseFloat(this.item["tva_val"]);
    }

    um(): string{
        return this.item_map.um
    }
    pret(): number{
        let uv = parseFloat(this.item['uv']) || 1;
        let pret = parseFloat(this.item['pret_unitar'])
        return  uv * pret / this.item_map.scalare;
    }
    denumire(): string{
        return this.item_map.denumire
    }

    scalare(): number{
        return this.item_map ? this.item_map.scalare : 0
    }

    shouldIgnore(): boolean{
        return this.item_map.ignored;
    }

    valid(): boolean{
        return this.has_denumire()
        && this.has_scaling()
        && this.has_um()
    }

    has_denumire(): boolean{
        return this.item_map.denumire !== "" || this.item_map.ignored == true
    }
    has_scaling(): boolean{
        return this.item_map.scalare !== 0 || this.item_map.ignored == true
    }
    has_um(): boolean{
        return this.item_map.um !== "" || this.item_map.ignored == true
    }
}

export default function NirItemHTML(props: any){
    let item = props.item;
    let item_map = props.mappings[item['denumire_articol']]
    item_map = item_map ? item_map : {"denumire": "", "scalare": 1, "um": "", "ignored": false}
    const nir_item = new NirItem(item, item_map);

    const scalingChange = (e: any) => {
        props.update_mapping(item['denumire_articol'], {...item_map, scalare: e.target.value})
    }

    const nameChange = (e: any) => {
        props.update_mapping(item['denumire_articol'], {...item_map, denumire: e.target.value})
    }

    const umChange = (e: any) => {
        props.update_mapping(item['denumire_articol'], {...item_map, um: e.target.value})
    }

    const ignoreChange = (e: any) => {
        props.update_mapping(item['denumire_articol'], {...item_map, ignored: !nir_item.shouldIgnore()})
    }
    return (
        <tr className={nir_item.shouldIgnore() ? "bg-secondary": ""}>
            <td>
                <input  className={nir_item.has_scaling() ? "": "border border-danger"}
                    type="text" 
                    value={nir_item.scalare()} 
                    style={{ width: "50px" }} 
                    onChange={(e) => scalingChange(e)} />
            </td>
            <td>
                <span className="fs-6 fw-light">{item['denumire_articol']}</span>
                <br />
                <input  className={nir_item.has_denumire() ? "": "border border-danger"}
                    type="text" 
                    value={nir_item.denumire()} 
                    onChange={(e) => nameChange(e)} />
            </td>
            <td>
                <span className="fs-6 fw-light">{item['ma']}</span>
                <br />
                <input  className={nir_item.has_um() ? "": "border border-danger"}
                    type="text" 
                    defaultValue={nir_item.um()} 
                    style={{ width: "50px" }} 
                    onChange={(e) => umChange(e)} />
            </td>
            <td>{nir_item.cantitate().toFixed(3)}</td>
            <td>{nir_item.pret().toFixed(4)}</td>
            <td>{nir_item.valoare_fara_tva().toFixed(2)}</td>
            <td>{nir_item.valoare_tva().toFixed(2)}</td>
            <td><input 
                type="checkbox" 
                checked={nir_item.shouldIgnore()} 
                onChange={(e) => ignoreChange(e)}/></td>
        </tr>
    )
}
