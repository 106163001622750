import NirItemHTML from './item';
import {ConstInterface} from "./definitions"
import { Component } from 'react';
//import items from "./f3_out.json";
import items from "./f1_out.json";
import f3_mappings from "./f3_mappings.json";
import {v4} from "uuid";
import HeaderNew from "./header_new"
import {CefContent} from "./CefContent";
declare var CefSharp: any;
declare var boundAsync: any;

interface AppState{
  items: any[];
  mappings: {[key: string]: ConstInterface}
}

class App extends Component<{}, AppState>{
  constructor(props: any){
    super(props)
    this.state = {} as AppState;
  }

  componentDidMount() {
    this.loadData();
  }

  private async loadData()  {
    if(typeof CefSharp !== 'undefined' && CefSharp && CefSharp.BindObjectAsync) {
      await CefSharp.BindObjectAsync('boundAsync');
      const cefContentString = await boundAsync.getDocumentContent() as string;
      const cefContent = JSON.parse(cefContentString) as CefContent;
      let mappingData = JSON.parse(cefContent.mappings);
      if(!mappingData){
        mappingData = f3_mappings;
      }
      this.setState({
        items: JSON.parse(cefContent.items),
        mappings: mappingData
      });
    }
  }

  update_map = (key: string, item_map: ConstInterface) =>{
    this.state.mappings[key] = item_map
    this.setState({mappings: {...this.state.mappings}})
  }

  submitChanges = () => {
    if(typeof boundAsync !== 'undefined'){
      boundAsync.submitData(JSON.stringify(this.state.mappings));
    }
  }

  render(){
    if(typeof CefSharp === 'undefined' && this.state.items === undefined){ //&& fields === undefined
      this.setState({
        items: items.map((el) => { return {...el, id: v4()}}),
        mappings: f3_mappings
      });
      return null;
    }
    if(this.state.items === undefined){
      return null;
    }

    return (
    <div className="App bg-light">
      <HeaderNew />
      <div className="container">
        <div className="row">
          <button name="Submit" className="mt-5 btn btn-primary" onClick={this.submitChanges}>Submit</button>
          <h3 className="mt-5">Date pentru introdus in NIR</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Factor de scalare</th>
                <th>Cod si denumire</th>
                <th>MA</th>
                <th>Cantitate</th>
                <th>Pret</th>
                <th>Valoare fara TVA</th>
                <th>Valoare TVA</th>
                <th>Exclude</th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map((el, ix: number) => 
              <NirItemHTML 
                key={this.state.items[ix]['id']} 
                item={this.state.items[ix]} 
                mappings={this.state.mappings} 
                update_mapping={this.update_map} />
              )}
            </tbody>
          </table>
          <div>
            {JSON.stringify(this.state.mappings)}
          </div>
        </div>
      </div>
    </div>
  );
  }
}
export default App;
